import Snowfall from 'react-snowfall';
import xmas from './knockout-flyer.svg';
function App() {
  return (
    <div className="app">
      <Snowfall
        color="white"
        style={{ background: '#ff0000' }}
        snowflakeCount={350}
      />
      <a
        href="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MG10NmVudXZjcHQyZ3BsdDVsM2Fkb2JjcDQgY180YTQ1Y2NlNzhlOGQ4YTI5OTczNDE0MzEyMDlmYzI3MGQzMTM2NDJjNzFhOTRhY2FjMmMwN2JkY2VjMGJhMGI5QGc&tmsrc=c_4a45cce78e8d8a2997341431209fc270d313642c71a94acac2c07bdcec0ba0b9%40group.calendar.google.com"
        target="_blank"
        rel="noreferrer noopener"
        className="inner"
      >
        <img src={xmas} alt="Alright Christmas Illo 2023" />
      </a>
    </div>
  );
}

export default App;
